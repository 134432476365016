body {
  display: block;
  font-family: Roboto;
  color: rgba(0, 0, 0, 1);
  width: 98%;
  height: 100%;
}

.BodyRow {
  display: flex;
  width: 100%;
}

.InfoColumn {
  width: 25%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  min-width: 275px;
}

.ChartColumn {
  width: 75%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 672px;
  height: 100%;
}

.Logo {
  font-weight: bold;
  font-size: 64px;
  text-align: left;
}

.DateLogo {
  color: #04957d;
}

.DashLogo {
  color: #225763;
}

.ChartHeader {
  height: 48px;
  text-align: left;
  display: flex;
  font-weight: bold;
  font-size: 36px;
}

.ChartHeaderText {
  align-self: flex-end;
}

.SubmitContainer {
  justify-content: center;
  display: flex;
  margin: 20px;
}

.ContactContainer {
  margin-top: auto;
}
