.DonutChart {
  width: 100%;
  height: 100%;
  position: absolute;
}

.DonutLabel {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.DonutContainer {
  height: 360px;
  width: 100%;
  position: relative;
}

.DonutHole {
}
