.Description {
  font-size: 14px;
  margin-top: 20px;
  text-align: left;
  line-height: 18px;
}

.MatchesJSON {
  font-weight: bold;
}
