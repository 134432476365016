.TreeMap,
.Matched,
.NoMatched,
.MatchedILike,
.MatchedTheyLike,
.NoMatchedILike,
.NoMatchedTheyLike {
  display: flex;
  border: 1px solid #ffffff;
  color: #ffffff;
  box-sizing: border-box;
}

.TreeMap {
  color: #ffffff;
  position: relative;
  background-color: #225763;
  min-width: 600px;
  height: 30px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

.Matched {
  background: #04957d;
  height: 30px;
  min-width: 300px;
  border-left: 0;
}

.NoMatched {
  background: #eeeeee;
  color: black;
  height: 30px;
  min-width: 300px;
  border-right: 0;
}

.MatchedILike {
  background: #04957d;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 170px;
  color: #ffffff;
  border-bottom: 0;
  border-left: 0;
}

.MatchedTheyLike {
  background: #04957d;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 170px;
  color: #ffffff;
  border-bottom: 0;
}

.NoMatchedILike {
  background: #eeeeee;
  color: black;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 170px;
  border-bottom: 0;
}

.NoMatchedTheyLike {
  background: #eeeeee;
  color: black;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  height: 170px;
  border-bottom: 0;
  border-right: 0;
}

.NumLabel {
  font-size: 36px;
}

.TotalInteractionsLabel,
.MatchedLabel,
.NoMatchedLabel {
  margin-left: 10px;
  min-width: 300px;
  align-self: flex-start;
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
}

.MatchedILikeLabel,
.MatchedTheyLikeLabel,
.NoMatchedILikeLabel,
.NoMatchedTheyLikeLabel {
  align-self: start;
  text-align: center;
  position: absolute;
  margin-top: 5px;
  min-width: 150px;
}

.MatchNoMatch {
  flex-direction: row;
  display: flex;
  min-width: 600px;
  height: 30px;
}
.IndividualBoxes {
  flex-direction: row;
  display: flex;
  min-width: 600px;
}

.TreeMapContainer {
  margin-top: 35px;
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
}
